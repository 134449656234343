import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { formatDate } from '../components/common';
import BioList from '../components/bio-list';
import styles from '../styles/course.module.css';
import Media from '../components/media';

export const query = graphql`
	query CourseTemplate($id: ID!) {
		cms {
			Course(where: { id: $id }) {
				name
				start
				description
				registrationLink
				instructors {
					id
					name
					slug
					image {
						publicUrl
						gatsbyFile {
							childImageSharp {
								fluid(maxWidth: 90, maxHeight: 90) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					email
					linkedin
					github
					gitlab
					title
				}
				tags {
					name
				}
				image {
					publicUrl
					gatsbyFile {
						childImageSharp {
							fluid(maxWidth: 700, maxHeight: 700) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`;

export default ({ data }) => (
	<Layout className='bg-course' title={data.cms.Course.name}>
		<SEO
			title={data.cms.Course.name}
			keywords={['Courses', ...data.cms.Course.tags.map(({ name }) => name)]}
			description={`IEEE SSCS AlexSC course${
				data.cms.Course.start ? ` starting from ${formatDate(data.cms.Course.start)}` : ''
			}.`}
			image={data.cms.Course.image.publicUrl}
		/>
		<h1>{data.cms.Course.name}</h1>
		<Img fluid={data.cms.Course.image.gatsbyFile.childImageSharp.fluid} className={styles.courseImage + ' paper'} />
		<BioList list={data.cms.Course.instructors} />
		<section className='paper p-4 rich-text'>
			<Media content={data.cms.Course.description} />
		</section>
		{data.cms.Course.registrationLink ? (
			<div className='flex-container'>
				<a href={data.cms.Course.registrationLink}>
					<button className={styles.registerationLink}>
						<span>Register</span>
					</button>
				</a>
			</div>
		) : (
			''
		)}
	</Layout>
);
